import { ButtonGroup, Chip, DialogContent, Divider, FormHelperText, Grid, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { Button, StyledChip } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: '1px solid #9E9E9E',
    margin: '3px',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      border: '1px solid #9E9E9E',
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const marksWidth = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
];

const marksLength = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 400,
    label: '400',
  },
  {
    value: 600,
    label: '600',
  },
  {
    value: 800,
    label: '800',
  },
  {
    value: 1000,
    label: '1000',
  },
];

const AsphaltLayerContent = ({ control, errors, register, exclude }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField label="Benämning" {...register('name')} error={Boolean(errors.name)} helperText={Boolean(errors.name) ? errors.name.message : null} sx={{ width: '100%', marginBottom: '20px' }} />
      </Grid>
      <Grid item xs={12}>
        <StyledChip label="LÄGGNINGSTYP" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="asphaltMethod"
          defaultValue=""
          control={control}
          rules={{ required: ERRORS.required }}
          render={({ field }) => (
            <StyledToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors.layerType) ? { border: '1px solid red' } : null}>
              <ToggleButton value="Maskinläggning">Maskinläggning</ToggleButton>
              <ToggleButton value="Handläggning">Handläggning</ToggleButton>
            </StyledToggleButtonGroup>
          )}
        />
        <Grid item xs={12}>
          <FormHelperText sx={{ color: 'red' }}>{Boolean(errors.asphaltMethod) ? errors.asphaltMethod.message : null}</FormHelperText>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledChip label="LAGERTYP" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="layerType"
          control={control}
          defaultValue=""
          rules={{ required: ERRORS.required }}
          render={({ field }) => (
            <StyledToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors.layerType) ? { border: '1px solid red' } : null}>
              <ToggleButton value="Slitlager">Slitlager</ToggleButton>
              <ToggleButton value="Bindlager">Bindlager</ToggleButton>
              <ToggleButton value="AG-lager">AG-lager</ToggleButton>
            </StyledToggleButtonGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText sx={{ color: 'red' }}>{Boolean(errors.layerType) ? errors.layerType.message : null}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <StyledChip label="TJOCKLEK [KG]" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="kilograms"
          control={control}
          defaultValue=""
          rules={{ required: ERRORS.required }}
          render={({ field }) => (
            <StyledToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors.kilograms) ? { border: '1px solid red', flexWrap: 'wrap' } : { flexWrap: 'wrap' }}>
              <ToggleButton value="50">50</ToggleButton>
              <ToggleButton value="60">60</ToggleButton>
              <ToggleButton value="70">70</ToggleButton>
              <ToggleButton value="80">80</ToggleButton>
              <ToggleButton value="90">90</ToggleButton>
              <ToggleButton value="100">100</ToggleButton>
              <ToggleButton value="110">110</ToggleButton>
              <ToggleButton value="120">120</ToggleButton>
              <ToggleButton value="130">130</ToggleButton>
              <ToggleButton value="140">140</ToggleButton>
              <ToggleButton value="150">150</ToggleButton>
            </StyledToggleButtonGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText sx={{ color: 'red' }}>{Boolean(errors.kilograms) ? errors.kilograms.message : null}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <StyledChip label="TYP" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="type"
          control={control}
          defaultValue=""
          rules={{ required: ERRORS.required }}
          render={({ field }) => (
            <StyledToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors.type) ? { border: '1px solid red' } : null}>
              <ToggleButton value="ABT">ABT</ToggleButton>
              <ToggleButton value="ABS">ABS</ToggleButton>
              <ToggleButton value="AG">AG</ToggleButton>
              <ToggleButton value="ABb">ABb</ToggleButton>
            </StyledToggleButtonGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText sx={{ color: 'red' }}>{Boolean(errors.type) ? errors.type.message : null}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <StyledChip label="STENSTORLEK" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="stoneSize"
          defaultValue=""
          control={control}
          rules={{ required: ERRORS.required }}
          render={({ field }) => (
            <StyledToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors.stoneSize) ? { border: '1px solid red' } : null}>
              <ToggleButton value="4">4</ToggleButton>
              <ToggleButton value="8">8</ToggleButton>
              <ToggleButton value="11">11</ToggleButton>
              <ToggleButton value="16">16</ToggleButton>
              <ToggleButton value="22">22</ToggleButton>
              <ToggleButton value="32">32</ToggleButton>
            </StyledToggleButtonGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText sx={{ color: 'red' }}>{Boolean(errors.stoneSize) ? errors.stoneSize.message : null}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <StyledChip label="BINDEMEDELSHALT" />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="bitumen"
          defaultValue=""
          control={control}
          rules={{ required: ERRORS.required }}
          render={({ field }) => (
            <StyledToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors.bitumen) ? { border: '1px solid red' } : null}>
              <ToggleButton value="70/100">70/100</ToggleButton>
              <ToggleButton value="150/200">150/200</ToggleButton>
              <ToggleButton value="160/220">160/220</ToggleButton>
            </StyledToggleButtonGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormHelperText sx={{ color: 'red' }}>{Boolean(errors.bitumen) ? errors.bitumen.message : null}</FormHelperText>
      </Grid>
      {!exclude?.includes('width') && (
        <>
          <Grid item xs={12}>
            <StyledChip label="BREDD" />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Controller
              name="width"
              control={control}
              defaultValue={6}
              rules={{ required: ERRORS.required }}
              render={({ field }) => <Slider {...field} valueLabelDisplay="on" step={0.5} marks={marksWidth} min={0.5} max={30} sx={{ width: '90%', marginTop: '30px' }} />}
            />
          </Grid>
        </>
      )}
      {!exclude?.includes('length') && (
        <>
          <Grid item xs={12}>
            <StyledChip label="LÄNGD" />
          </Grid>

          <Grid container item xs={12} justifyContent="center">
            <Controller
              name="length"
              control={control}
              defaultValue={3}
              rules={{ required: ERRORS.required }}
              render={({ field }) => <Slider {...field} valueLabelDisplay="on" step={1} marks={marksLength} min={1} max={1000} sx={{ width: '90%', marginTop: '30px' }} />}
            />
          </Grid>
        </>
      )}
      <Grid container item xs={12} justifyContent="center">
        {!exclude?.includes('notes') && <TextField multiline rows={4} {...register('notes')} label="Anteckningar" variant="outlined" fullWidth sx={{ marginTop: '20px' }}></TextField>}
      </Grid>
    </Grid>
  );
};

export default AsphaltLayerContent;
