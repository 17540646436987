import { DialogActions, DialogContent, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import debounce from 'debounce';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Button, TextField } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';
import AddAreaDialog from './AddAreaDialog';
import AddIntegerDialog from './AddIntegerDialog';
import AddKubicDialog from './AddKubicDialog';
import AddObjectDialog from './AddObjectDialog';
import AsphaltLayer from './AsphaltLayer';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopySharp';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, remove, set } from 'firebase/database';
import database from '../../firebase/firebase';
import AddMultipleItemsDialog from './AddMultipleItemsDialog';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { useGeolocation } from '../../components/hooks/useGeolocation';
import Map from '../Map/Map';

function NewObject(props) {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();
  const { projectId } = useParams();
  console.log('projectId', projectId);
  const navigate = useNavigate();
  const geoLocation = useGeolocation();

  const [activeObject, setActiveObject] = useState(null);
  const [activeGroups, setActiveGroups] = useState([]);
  const [openMap, setOpenMap] = useState(false);
  const [openDeleteGroup, setOpenDeleteGroup] = useState(null);
  const [openCloneGroup, setOpenCloneGroup] = useState(null);
  const [openDeleteGroupItems, setOpenDeleteGroupItems] = useState(null);
  const [openAsphaltLayers, setOpenAsphaltLayers] = useState(false);
  const [openAddArea, setOpenAddArea] = useState(false);
  const [openAddKubic, setOpenAddKubic] = useState(false);
  const [openAddObject, setOpenAddObject] = useState(false);
  const [openAddMultipleItems, setOpenAddMultipleItems] = useState(false);
  const [openIntegerDialog, setOpenIntegerDialog] = useState(false);
  const [activeFieldName, setActiveFieldName] = useState(null);
  const [isEditingField, setIsEditingField] = useState(null);

  const [activeField, setActiveField] = useState(null);
  const [activeObjects, setActiveObjects] = useState(null);

  const onSubmit = (data) => {
    // const allObjects = localStorage.getItem('allObjects') ? localStorage.getItem('allObjects') : JSON.stringify([]);
    // const objectsUpdated = [...JSON.parse(allObjects)];
    // const objectIndex = objectsUpdated.findIndex((object) => object.id === activeObject.id);

    // if (objectIndex > -1) {
    //   objectsUpdated[objectIndex] = activeObject;
    //   localStorage.setItem('allObjects', JSON.stringify(objectsUpdated));
    // } else {
    //   objectsUpdated.push(activeObject);
    //   localStorage.setItem('allObjects', JSON.stringify(objectsUpdated));
    // }
    set(ref(database, 'projects/' + projectId + '/surveys/' + activeObject.id), JSON.stringify(activeObject));
    remove(ref(database, 'restricted-surveys/' + activeObject.id));
    localStorage.removeItem('activeObject');
    navigate('/home/' + projectId);
  };
  const handleSubmitCloneGroup = (data) => {
    console.log('group', openCloneGroup);
    cloneGroup(openCloneGroup.groupId, openCloneGroup.index, data.groupName);
  };
  const submitArrayField = (data, field) => {
    const currentValues = activeObject[field];
    if (Array.isArray(currentValues)) {
      if (Boolean(isEditingField)) {
        console.log('geoLocation', geoLocation);
        if (geoLocation) {
          data.location = geoLocation;
        }
        currentValues[isEditingField.index] = data;
        onChangeField(field, currentValues);
        setIsEditingField(null);
      } else {
        console.log('geoLocation', geoLocation);
        if (geoLocation) {
          data.location = geoLocation;
        }
        currentValues.push(data);
        onChangeField(field, currentValues);
      }
    }
  };
  const submitMultiArrayField = (data) => {
    console.log('geoLocation', geoLocation);
    if (geoLocation) {
      data.location = geoLocation;
    }
    const groupId = uuid();
    const objectsUpdated = { ...activeObject };
    data.selectedTypes.forEach((selectedType) => {
      const selectedCalculation = calculations.find((calculation) => calculation.fieldName === selectedType);
      const currentValues = activeObject[selectedType];
      const calculatedFieldForm = {};
      let calculatedAdditiveField = null;
      calculatedFieldForm['groupId'] = groupId;
      calculatedFieldForm['location'] = geoLocation ? geoLocation : null;

      selectedCalculation.schema.forEach((formField) => {
        calculatedFieldForm[formField] = data[formField];
      });

      currentValues.push(calculatedFieldForm);

      objectsUpdated[selectedType] = currentValues;

      if (selectedCalculation.additiveFields) {
        selectedCalculation.additiveFields.forEach((additiveField) => {
          const currentAdditiveValues = activeObject[additiveField];
          const selectedAdditiveField = calculations.find((calculation) => calculation.fieldName === additiveField);
          calculatedAdditiveField = calculateAdditive(selectedCalculation, selectedAdditiveField, calculatedFieldForm);
          console.log('calculatedAdditiveField[selectedAdditiveField.fieldName]', calculatedAdditiveField[selectedAdditiveField.fieldName]);
          currentAdditiveValues.push(calculatedAdditiveField);
          objectsUpdated[additiveField] = currentAdditiveValues;
        });
      }
    });
    const activeGroupCopy = activeGroups ? [...activeGroups] : [];
    activeGroupCopy.push({ id: groupId, name: data.groupName });
    objectsUpdated.activeGroups = activeGroupCopy;
    localStorage.setItem('activeObject', JSON.stringify(objectsUpdated));
    setActiveGroups(activeGroupCopy);
    setActiveObject(objectsUpdated);
    setActiveFieldName(null);
    setActiveObjects(null);
  };

  const calculateAdditive = (selectedCalculation, additiveCalculation, calculatedFieldForm) => {
    switch (additiveCalculation.fieldName) {
      case 'excavatorAdjustmentGravel':
        console.log('selectedCalculation', calculatedFieldForm);
        return {
          [additiveCalculation.fieldName]: calculatedFieldForm.width * calculatedFieldForm.length * calculatedFieldForm.thickness * additiveCalculation.density,
          groupId: calculatedFieldForm.groupId,
        };
      default:
        break;
    }
  };

  const cloneGroup = (groupId, groupIndex, groupName) => {
    console.log('GURKLA', groupId, groupIndex);
    const fields = [];
    const activeObjectCopy = { ...activeObject };
    const activeGroupsCopy = [...activeGroups];
    const newGroupId = uuid();
    const validObjects = Object.keys(activeObject).filter((key) => Array.isArray(activeObject[key]) && key !== 'activeGroups');

    validObjects.forEach((key) => {
      console.log('key', key, groupId);
      const object = activeObject[key].find((object) => object.groupId === groupId);
      console.log('key', key, object);
      if (object) {
        activeObjectCopy[key].push({ ...object, groupId: newGroupId });
      }

      //fields.push({ fieldName: key, index: objectIdIndex });
    });
    console.log('activeObjectCopy', activeObjectCopy);
    activeGroupsCopy.push({ id: newGroupId, name: groupName });
    activeObjectCopy.activeGroups = activeGroupsCopy;
    setActiveGroups(activeGroupsCopy);
    setActiveObject(activeObjectCopy);
    setOpenCloneGroup(null);
  };
  const handleDeleteGroup = (groupId, groupIndex) => {
    console.log('GURKLA', groupId);
    const fields = [];
    const validObjects = Object.keys(activeObject).filter((key) => Array.isArray(activeObject[key]));
    validObjects.forEach((key) => {
      const objectIdIndex = activeObject[key].findIndex((object) => object.groupId === groupId);
      fields.push({ fieldName: key, index: objectIdIndex });
    });

    fields.forEach((field) => deleteItem(field.fieldName, field.index));
    const activeGroupsCopy = activeGroups.splice(groupIndex, 1);
    setActiveGroups(activeGroupsCopy);
  };

  const handleDeleteItem = (fieldName, index) => {
    console.log('ADSADSDD');
    if (
      activeObject[fieldName][index].groupId &&
      Object.keys(activeObject).filter((key) => key !== fieldName && Array.isArray(activeObject[key]) && activeObject[key].find((object) => object.groupId === activeObject[fieldName][index].groupId))
        .length > 0
    ) {
      setOpenDeleteGroupItems({ fieldName: fieldName, index: index });
    } else {
      deleteItem(fieldName, index);
    }
  };
  const deleteGroupItems = (fieldName, index) => {
    const fields = [{ fieldName: fieldName, index: index }];
    const validObjects = Object.keys(activeObject).filter((key) => Array.isArray(activeObject[key]));
    validObjects.forEach((key) => {
      const objectIdIndex = activeObject[key].findIndex((object) => object.groupId === activeObject[fieldName][index].groupId);
      fields.push({ fieldName: key, index: objectIdIndex });
    });
    fields.forEach((field) => deleteItem(field.fieldName, field.index));
    //  validObjects.forEach(objectKey => activeObject[objectKey].find(element => element.reference))
    console.log('validObjects', validObjects);
  };

  const deleteItem = (fieldName, index) => {
    const objectsUpdated = { ...activeObject };
    objectsUpdated[fieldName].splice(index, 1);

    localStorage.setItem('activeObject', JSON.stringify(objectsUpdated));
    setActiveObject(objectsUpdated);
  };
  const editSelectedField = (object, index) => {
    const { fieldName, type, label, unit, objects } = object;
    object.index = index;
    object.activeObject = activeObject[fieldName];
    switch (type) {
      case 'asphaltLayer':
        setActiveFieldName(fieldName);
        setOpenAsphaltLayers(true);
        break;
      case 'area':
        setActiveFieldName(fieldName);
        setOpenAddArea(true);
        break;
      case 'kubic':
        setActiveFieldName(fieldName);
        setOpenAddKubic(true);
        break;
      case 'object':
        setActiveFieldName(fieldName);
        setOpenAddObject(true);
        break;
      case 'integer':
        setActiveFieldName(fieldName);
        setOpenIntegerDialog(true);
        break;
      default:
        break;
    }
    console.log('MAMMA', object);
    setIsEditingField(object);
  };
  //TODO måste göra något åt hur allt sparas när jag lämnat react hook forms.
  const onChangeField = (name, currentValues) => {
    const value = currentValues;
    const objectsUpdated = { ...activeObject };
    objectsUpdated[name] = value;

    localStorage.setItem('activeObject', JSON.stringify(objectsUpdated));
    setActiveObject(objectsUpdated);
    setActiveFieldName(null);
    setActiveObjects(null);
  };

  const onChangeFormField = (name) => {
    const value = getValues(name);
    const objectsUpdated = { ...activeObject };
    objectsUpdated[name] = value;

    localStorage.setItem('activeObject', JSON.stringify(objectsUpdated));
    setActiveObject(objectsUpdated);
    setActiveFieldName(null);
    setActiveObjects(null);
  };

  const colors = [
    { list: '#EF9A9A', oddItems: '#FFEBEE', evenItems: '#FFCDD2' }, // Red
    { list: '#F48FB1', oddItems: '#FCE4EC', evenItems: '#F8BBD0' }, // Pink
    { list: '#CE93D8', oddItems: '#F3E5F5', evenItems: '#E1BEE7' }, // Purple
    { list: '#B39DDB', oddItems: '#EDE7F6', evenItems: '#D1C4E9' }, // Deep purple
    { list: '#9FA8DA', oddItems: '#E8EAF6', evenItems: '#C5CAE9' }, // Indigo
    { list: '#90CAF9', oddItems: '#E3F2FD', evenItems: '#BBDEFB' }, // Blue
    { list: '#81D4FA', oddItems: '#E1F5FE', evenItems: '#B3E5FC' }, // Light Blue
    { list: '#80DEEA', oddItems: '#E0F7FA', evenItems: '#B2EBF2' }, // Cyan
    { list: '#80CBC4', oddItems: '#E0F2F1', evenItems: '#B2DFDB' }, // Teal
    { list: '#A5D6A7', oddItems: '#E8F5E9', evenItems: '#C8E6C9' }, // Green
    { list: '#C5E1A5', oddItems: '#F1F8E9', evenItems: '#F0F4C3' }, // Light Green
    { list: '#E6EE9C', oddItems: '#F9FBE7', evenItems: '#F0F4C3' }, // Lime
    { list: '#FFF59D', oddItems: '#FFFDE7', evenItems: '#FFF9C4' }, // Yellow
    { list: '#FFE082', oddItems: '#FFF8E1', evenItems: '#FFECB3' }, // Amber
    { list: '#FFCC80', oddItems: '#FFF3E0', evenItems: '#FFE0B2' }, // Orange
    { list: '#FFAB91', oddItems: '#FBE9E7', evenItems: '#FFCCBC' }, // Deep Orange
    { list: '#BCAAA4', oddItems: '#EFEBE9', evenItems: '#D7CCC8' }, // Brown
    { list: '#B0BEC5', oddItems: '#FAFAFA', evenItems: '#F5F5F5' }, // Grey
    { list: '#EF9A9A', oddItems: '#FFEBEE', evenItems: '#FFCDD2' }, // Red
    { list: '#F48FB1', oddItems: '#FCE4EC', evenItems: '#F8BBD0' }, // Pink
    { list: '#CE93D8', oddItems: '#F3E5F5', evenItems: '#E1BEE7' }, // Purple
    { list: '#B39DDB', oddItems: '#EDE7F6', evenItems: '#D1C4E9' }, // Deep purple
    { list: '#9FA8DA', oddItems: '#E8EAF6', evenItems: '#C5CAE9' }, // Indigo
    { list: '#90CAF9', oddItems: '#E3F2FD', evenItems: '#BBDEFB' }, // Blue
    { list: '#81D4FA', oddItems: '#E1F5FE', evenItems: '#B3E5FC' }, // Light Blue
    { list: '#80DEEA', oddItems: '#E0F7FA', evenItems: '#B2EBF2' }, // Cyan
    { list: '#80CBC4', oddItems: '#E0F2F1', evenItems: '#B2DFDB' }, // Teal
    { list: '#A5D6A7', oddItems: '#E8F5E9', evenItems: '#C8E6C9' }, // Green
    { list: '#C5E1A5', oddItems: '#F1F8E9', evenItems: '#F0F4C3' }, // Light Green
    { list: '#E6EE9C', oddItems: '#F9FBE7', evenItems: '#F0F4C3' }, // Lime
    { list: '#FFF59D', oddItems: '#FFFDE7', evenItems: '#FFF9C4' }, // Yellow
    { list: '#FFE082', oddItems: '#FFF8E1', evenItems: '#FFECB3' }, // Amber
    { list: '#FFCC80', oddItems: '#FFF3E0', evenItems: '#FFE0B2' }, // Orange
    { list: '#FFAB91', oddItems: '#FBE9E7', evenItems: '#FFCCBC' }, // Deep Orange
    { list: '#BCAAA4', oddItems: '#EFEBE9', evenItems: '#D7CCC8' }, // Brown
    { list: '#B0BEC5', oddItems: '#FAFAFA', evenItems: '#F5F5F5' }, // Grey
  ];

  const calculations = [
    {
      fieldName: 'asphaltLayers',
      type: 'asphaltLayer',
      label: 'Nytt asfaltslager',
      unit: 'm2',
      schema: ['asphaltMethod', 'type', 'stoneSize', 'bitumen', 'kilograms', 'layerType', 'length', 'width'],
    },
    { fieldName: 'connectionMilling', type: 'area', label: 'Anslutningsfräsning', unit: 'm2', schema: ['length', 'width'] },
    { fieldName: 'machineAdjustment', type: 'integer', label: 'Maskinjustering', unit: 'ton', schema: ['machineAdjustment'] },
    { fieldName: 'demolition', type: 'area', label: 'Rivning', unit: 'm2', schema: ['length', 'width'] },
    { fieldName: 'milling', type: 'area', label: 'Fräsning', unit: 'm2', schema: ['length', 'width'] },
    { fieldName: 'adjustCurbstone', type: 'integer', label: 'Justering bef. råkantsten', unit: 'm', schema: ['adjustCurbstone'] },
    { fieldName: 'shafting', type: 'kubic', label: 'Jordschakt', unit: 'm3', schema: ['length', 'width', 'thickness'] },
    { fieldName: 'supportStrip', type: 'integer', label: 'Stödremsa', unit: 'm', schema: ['supportStrip'] },
    { fieldName: 'excavatorAdjustment', type: 'kubic', label: 'Fyllning', unit: 'm3', schema: ['length', 'width', 'thickness'], additiveFields: ['excavatorAdjustmentGravel'] },
    { fieldName: 'excavatorAdjustmentGravel', type: 'integer', label: 'Obundet bärlager', unit: 'ton', density: 1.85, schema: ['excavatorAdjustmentGravel'], multiHidden: true },
    { fieldName: 'edgeCutting', type: 'integer', label: 'Kantskärning', unit: 'm', schema: ['edgeCutting'] },
    { fieldName: 'newGraniteCurbStone', type: 'integer', label: 'Kantstöd granit', unit: 'm', schema: ['newGraniteCurbStone'] },
    { fieldName: 'newConcreteCurbStone', type: 'integer', label: 'Kantstöd betong, spikade', unit: 'm', schema: ['newConcreteCurbStone'] },
    { fieldName: 'recoveryExcavation', type: 'area', label: 'Återställningar', unit: 'm2', schema: ['length', 'width'] },
    { fieldName: 'recoveryGraniteCurbStone', type: 'area', label: 'Återstl. kantstöd, granit', unit: 'm2', schema: ['length', 'width'] },
    { fieldName: 'recoveryConcreteCurbStone', type: 'area', label: 'Återstl. kantstöd, betong', unit: 'm2', schema: ['length', 'width'] },
    { fieldName: 'adjustFloatingWellCover', type: 'integer', label: 'Höjning flytande bet.', unit: 'st', schema: ['adjustFloatingWellCover'] },
    {
      fieldName: 'roadLines',
      type: 'object',
      label: 'Väglinjemålning',
      unit: '',
      objects: [
        { value: 'Längdsgående markering på väg/gata Bredd 0,1 m, avser alla typer av linjer med olika uppehåll och olika placering', unit: 'm' },
        { value: 'Markering på parkering, skolgård och dylikt samt tvärgående markeringar på alla typer av ytor Bredd 0,1 m', unit: 'm' },
        { value: 'Heldragen linje H(0,2)', unit: 'm' },
        { value: 'Övergångsställe H(0,5)', unit: 'm' },
        { value: 'Väjningslinje', unit: 'st' },
        { value: 'Cykelöverfart 0,5(0,5)+0,5', unit: 'st' },
        { value: 'Stopplinje H(0,4)', unit: 'm' },
        { value: 'Gångsymbol, Cykelsymbol, Handikappsymbol, Bokstäver', unit: 'st' },
        { value: 'Körfältspil', unit: 'st' },
      ],
    },
    {
      fieldName: 'changeWellCover',
      type: 'object',
      label: 'Utbyte till flytande bet.',
      unit: '',
      objects: [
        { value: 'Nedstigningsbrunn typ A6', unit: 'st' },
        { value: 'Tillsynsbrunn A5, spolbrunn A3, brandpost och rännstensbrunn', unit: 'st' },
        { value: 'Avstängningsventil AV, serviceventil SV, polygonpunkt PP', unit: 'st' },
      ],
    },
    {
      fieldName: 'adjustSolidWellCover',
      type: 'object',
      label: 'Höjning av fast betäckning',
      unit: '',
      objects: [
        { value: 'Nedstigningsbrunn typ A6', unit: 'st' },
        { value: 'Tillsynsbrunn A5, spolbrunn A3, brandpost och rännstensbrunn', unit: 'st' },
        { value: 'Avstängningsventil AV, serviceventil SV, polygonpunkt PP', unit: 'st' },
      ],
    },
  ];

  const calculationsPerHour = [
    { fieldName: 'blueCollarGuard', type: 'integer', label: 'Trafikvakt', unit: 'tim' },
    { fieldName: 'blueCollar', type: 'integer', label: 'Yrkesarbetare', unit: 'tim' },
    { fieldName: 'excavator', type: 'integer', label: 'Grävmaskin', unit: 'tim' },
    { fieldName: 'grader', type: 'integer', label: 'Väghyvel', unit: 'tim' },
    { fieldName: 'truck', type: 'integer', label: 'Lastbil', unit: 'tim' },
    { fieldName: 'truckTrailerAddon', type: 'integer', label: 'Tillägg tippsläp lastbil', unit: 'tim' },
    { fieldName: 'truckSaltAddon', type: 'integer', label: 'Tillägg saltspridare lastbil', unit: 'tim' },
    { fieldName: 'bitumenTruckRepairer', type: 'integer', label: 'Snabellagning', unit: 'tim' },
    { fieldName: 'roller', type: 'integer', label: 'Vält 4-8 ton', unit: 'tim' },
    { fieldName: 'nightShiftBlueCollar', type: 'integer', label: 'Beordrat arbete 18-06', unit: 'mantim' },
    { fieldName: 'addedGravel', type: 'integer', label: 'Tillfört material exkl. transport', unit: 'ton' },
    { fieldName: 'materialSalt', type: 'integer', label: 'Vägsalt, magnesiumklorid', unit: 'ton' },
  ];

  const populateType = (object, colorIndex) => {
    const { fieldName, type, label, unit, objects } = object;

    switch (type) {
      case 'asphaltLayer':
        return (
          <Paper key={colorIndex} elevation={1} sx={{ margin: '10px 5px', backgroundColor: colors[colorIndex].list, padding: '0px 5px' }}>
            <List>
              {activeObject[fieldName].length > 0 && (
                <>
                  {activeObject[fieldName].map((layer, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={index % 2 ? { background: colors[colorIndex].oddItems } : { background: colors[colorIndex].evenItems }}
                      secondaryAction={
                        <ListItemText
                          primary={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(fieldName, index)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      }
                    >
                      <ListItemButton>
                        {/* | ${layer.width} x ${layer.length} = ${
                            parseInt(layer.width) * parseInt(layer.length)
                          } m2 */}
                        <ListItemText
                          onClick={() => editSelectedField(object, index)}
                          primary={`${layer.kilograms} kg ${layer.type} ${layer.stoneSize} ${layer.bitumen} ${layer.layerType}`}
                          secondary={
                            layer.groupId
                              ? `Grupp: ${activeGroups.find((group) => group.id === layer.groupId).name} - ${layer.asphaltMethod} - ${layer.width} x ${layer.length} = ${
                                  parseInt(layer.width) * parseInt(layer.length)
                                } ${object.unit}`
                              : `${layer.name} - ${layer.asphaltMethod} - ${layer.width} x ${layer.length} = ${parseInt(layer.width) * parseInt(layer.length)} ${object.unit}`
                          }
                          primaryTypographyProps={{
                            variant: 'subtitle2',
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              <ListItem
                disablePadding
                onClick={() => {
                  setOpenAsphaltLayers(true);
                  setActiveField(object);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<b>{label}</b>}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        );
      case 'area':
        console.log('WOOT', fieldName, activeObject[fieldName]);
        return (
          <Paper key={colorIndex} elevation={1} sx={{ margin: '10px 5px', backgroundColor: colors[colorIndex].list, padding: '0px 5px' }}>
            <List>
              {activeObject[fieldName].length > 0 && (
                <>
                  {activeObject[fieldName].map((data, index) => {
                    return (
                      <ListItem
                        key={index}
                        disablePadding
                        sx={index % 2 ? { background: colors[colorIndex].oddItems } : { background: colors[colorIndex].evenItems }}
                        secondaryAction={
                          <ListItemText
                            primary={
                              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(fieldName, index)}>
                                <DeleteIcon />
                              </IconButton>
                            }
                          />
                        }
                      >
                        <ListItemButton>
                          <ListItemText
                            onClick={() => editSelectedField(object, index)}
                            primary={`${data.width} x ${data.length} = ${parseInt(data.width) * parseInt(data.length)} m2`}
                            secondary={data.groupId ? `Grupp: ${activeGroups?.find((group) => group.id === data.groupId).name}` : data.name}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </>
              )}
              <ListItem
                disablePadding
                onClick={() => {
                  setActiveFieldName(fieldName);
                  setActiveField(object);
                  setOpenAddArea(true);
                }}
                secondaryAction={
                  <ListItemText
                    primary={
                      <b>
                        Total: {parseFloat(activeObject[fieldName].reduce((n, { width, length }) => n + parseInt(width) * parseInt(length), 0)).toFixed(2)} {unit}
                      </b>
                    }
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<b>{`${label}`}</b>}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        );
      case 'integer':
        return (
          <Paper key={colorIndex} elevation={1} sx={{ margin: '10px 5px', backgroundColor: colors[colorIndex].list, padding: '0px 5px' }}>
            <List>
              {activeObject[fieldName].length > 0 && (
                <>
                  {activeObject[fieldName].map((data, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={index % 2 ? { background: colors[colorIndex].oddItems } : { background: colors[colorIndex].evenItems }}
                      secondaryAction={
                        <ListItemText
                          primary={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(fieldName, index)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      }
                    >
                      <ListItemButton>
                        <ListItemText
                          onClick={() => editSelectedField(object, index)}
                          primary={<b>{`${data[fieldName]} ${unit}`}</b>}
                          secondary={data.groupId ? `Grupp: ${activeGroups.find((group) => group.id === data.groupId).name}` : data.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              <ListItem
                disablePadding
                onClick={() => {
                  setActiveFieldName(fieldName);
                  setOpenIntegerDialog(true);
                  setActiveField(object);
                }}
                secondaryAction={
                  <ListItemText
                    primary={
                      <b>
                        Total: {parseFloat(activeObject[fieldName].reduce((n, data) => n + parseInt(data[fieldName]), 0)).toFixed(2)} {unit}
                      </b>
                    }
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<b>{`${label}`}</b>}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        );
      case 'kubic':
        return (
          <Paper key={colorIndex} elevation={1} sx={{ margin: '10px 5px', backgroundColor: colors[colorIndex].list, padding: '0px 5px' }}>
            <List>
              {activeObject[fieldName].length > 0 && (
                <>
                  {activeObject[fieldName].map((data, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={index % 2 ? { background: colors[colorIndex].oddItems } : { background: colors[colorIndex].evenItems }}
                      secondaryAction={
                        <ListItemText
                          primary={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(fieldName, index)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      }
                    >
                      <ListItemButton>
                        <ListItemText
                          onClick={() => editSelectedField(object, index)}
                          primary={`${data.width} x ${data.length} x ${data.thickness} = ${parseFloat(parseInt(data.width) * parseInt(data.length) * parseFloat(data.thickness)).toFixed(2)}`}
                          secondary={data.groupId ? `Grupp: ${activeGroups.find((group) => group.id === data.groupId).name}` : data.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              <ListItem
                disablePadding
                onClick={() => {
                  setActiveFieldName(fieldName);
                  setOpenAddKubic(true);
                  setActiveField(object);
                }}
                secondaryAction={
                  <ListItemText
                    primary={
                      <b>
                        Total: {parseFloat(activeObject[fieldName].reduce((n, { width, length, thickness }) => n + parseInt(width) * parseInt(length) * parseFloat(thickness), 0)).toFixed(2)} {unit}
                      </b>
                    }
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<b>{`${label}`}</b>}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        );
      case 'object':
        return (
          <Paper key={colorIndex} elevation={1} sx={{ margin: '10px 5px', backgroundColor: colors[colorIndex].list, padding: '0px 5px' }}>
            <List>
              {activeObject[fieldName].length > 0 && (
                <>
                  {activeObject[fieldName].map((data, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={index % 2 ? { background: colors[colorIndex].oddItems } : { background: colors[colorIndex].evenItems }}
                      secondaryAction={
                        <ListItemText
                          onClick={() => editSelectedField(object, index)}
                          primary={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(fieldName, index)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      }
                    >
                      <ListItemButton>
                        <ListItemText
                          primary={`${data[fieldName]} ${data.length} ${data.unit}`}
                          secondary={data.groupId ? `Grupp: ${activeGroups.find((group) => group.id === data.groupId).name}` : data.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              <ListItem
                disablePadding
                onClick={() => {
                  setActiveFieldName(fieldName);
                  setActiveObjects(objects);
                  setActiveField(object);

                  setOpenAddObject(true);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<b>{`${label}`}</b>}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        );
      default:
        return <div>error</div>;
    }
  };

  useEffect(() => {
    const objects = localStorage.getItem('activeObject');
    if (objects) {
      const decodedObjects = JSON.parse(objects);
      if (decodedObjects) {
        localStorage.setItem('activeObject', JSON.stringify(decodedObjects));
        setActiveObject(decodedObjects);
        setActiveGroups(decodedObjects.activeGroups);
        setValue('name', decodedObjects.name);
      }
    } else {
      const newObject = {
        projectId: projectId,
        id: uuid(),
        asphaltLayers: [],
        activeGroups: [],
        connectionMilling: [],
        machineAdjustment: [],
        demolition: [],
        milling: [],
        adjustCurbstone: [],
        shafting: [],
        supportStrip: [],
        excavatorReinforcedLayer: [],
        excavatorReinforcedLayerGravel: [],
        excavatorAdjustment: [],
        excavatorAdjustmentGravel: [],
        edgeCutting: [],
        newGraniteCurbStone: [],
        newConcreteCurbStone: [],
        recoveryExcavation: [],
        recoveryGraniteCurbStone: [],
        recoveryConcreteCurbStone: [],
        roadLines: [],
        changeWellCover: [],
        adjustSolidWellCover: [],
        adjustFloatingWellCover: [],
        blueCollar: [],
        blueCollarGuard: [],
        excavator: [],
        grader: [],
        truck: [],
        truckSaltAddon: [],
        truckTrailerAddon: [],
        bitumenTruckRepairer: [],
        roller: [],
        nightShiftBlueCollar: [],
        addedGravel: [],
        materialSalt: [],
        name: '',
      };
      const objects = newObject;
      localStorage.setItem('activeObject', JSON.stringify(objects));
      setActiveObject(objects);
    }
  }, []);

  if (!activeObject) {
    return <div>inga objekt</div>;
  }
  console.log('is area open', openAddArea);
  return (
    <>
      <SimpleDialog open={Boolean(openCloneGroup)} onClose={() => setOpenCloneGroup(null)} title="Ta bort gruppobjekt">
        <form onSubmit={handleSubmit2(handleSubmitCloneGroup)}>
          <DialogContent>
            <TextField {...register2('groupName')} defaultValue={openCloneGroup?.oldName ? openCloneGroup.oldName : ''} label="Gruppnamn" variant="outlined" fullWidth></TextField>
          </DialogContent>
          <DialogActions>
            <Grid container item xs={12} justifyContent="flex-end">
              <Button onClick={() => setOpenCloneGroup(null)} color="grey">
                Avbryt
              </Button>
              <Button
                type="submit"
                // onClick={() => {
                //   cloneGroup(openCloneGroup.id, openCloneGroup.index);
                //   setOpenCloneGroup(null);
                // }}
              >
                Klona
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </SimpleDialog>
      <SimpleDialog open={Boolean(openDeleteGroup)} onClose={() => setOpenDeleteGroup(null)} title="Ta bort gruppobjekt">
        <DialogContent>Vill du ta bort gruppen och samtliga delmoment?</DialogContent>
        <DialogActions>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button onClick={() => setOpenDeleteGroup(null)} color="grey">
              Avbryt
            </Button>
            <Button
              onClick={() => {
                handleDeleteGroup(openDeleteGroup.id, openDeleteGroup.index);
                setOpenDeleteGroup(null);
              }}
            >
              Bekräfta
            </Button>
          </Grid>
        </DialogActions>
      </SimpleDialog>
      <SimpleDialog open={Boolean(openDeleteGroupItems)} onClose={() => setOpenDeleteGroupItems(null)} title="Ta bort gruppobjekt">
        <DialogContent>Det valda delmomentet är del i en grupp av flera delmoment. Välj åtgärd om hela gruppen ska raderas eller endast detta delmoment.</DialogContent>
        <DialogActions>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button onClick={() => setOpenDeleteGroupItems(null)} color="grey">
              Avbryt
            </Button>
            <Button
              onClick={() => {
                deleteItem(openDeleteGroupItems.fieldName, openDeleteGroupItems.index);
                setOpenDeleteGroupItems(null);
              }}
            >
              Delmoment
            </Button>
            <Button
              onClick={() => {
                deleteGroupItems(openDeleteGroupItems.fieldName, openDeleteGroupItems.index);
                setOpenDeleteGroupItems(null);
              }}
            >
              Grupp
            </Button>
          </Grid>
        </DialogActions>
      </SimpleDialog>
      <Map
        open={openMap}
        onClose={() => {
          setOpenMap(false);
        }}
        activeObject={activeObject}
        calculations={calculations}
        setOpenMap={setOpenMap}
        title="Min karta"
        fullScreen
      />
      <AsphaltLayer
        open={openAsphaltLayers}
        onClose={() => {
          setOpenAsphaltLayers(false);
          setIsEditingField(null);
        }}
        onSubmit={submitArrayField}
        isEditingField={isEditingField}
        title="Nytt asfaltslager"
        fullScreen
      />
      <AddAreaDialog
        fieldName={activeFieldName}
        open={openAddArea}
        onClose={() => {
          setOpenAddArea(false);
          setIsEditingField(null);
        }}
        onSubmit={submitArrayField}
        isEditingField={isEditingField}
        title={activeField?.label}
        fullScreen
      />
      <AddKubicDialog
        fieldName={activeFieldName}
        open={openAddKubic}
        onClose={() => {
          setOpenAddKubic(false);
          setIsEditingField(null);
        }}
        onSubmit={submitArrayField}
        isEditingField={isEditingField}
        title={activeField?.label}
        fullScreen
      />
      <AddObjectDialog
        fieldName={activeFieldName}
        open={openAddObject}
        label={activeField?.label}
        objects={activeObjects}
        onClose={() => {
          setOpenAddObject(false);
          setIsEditingField(null);
        }}
        onSubmit={submitArrayField}
        isEditingField={isEditingField}
        title="Nytt objekt"
        fullScreen
      />
      <AddMultipleItemsDialog
        open={openAddMultipleItems}
        label={activeField?.label}
        calculations={calculations}
        onClose={() => {
          setOpenAddMultipleItems(false);
          setIsEditingField(null);
        }}
        onSubmit={submitMultiArrayField}
        isEditingField={isEditingField}
        title="Nytt multiobjekt"
        fullScreen
      />
      <AddIntegerDialog
        fieldName={activeFieldName}
        open={openIntegerDialog}
        onClose={() => setOpenIntegerDialog(false)}
        onSubmit={submitArrayField}
        isEditingField={isEditingField}
        title={activeField?.label}
        schema={activeField?.schema}
        fullScreen
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenMap(true);
              }}
            >
              Visa på karta
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Namn"
              {...register('name', {
                onChange: debounce((e) => onChangeFormField('name'), 500),
                required: ERRORS.required,
                minLength: {
                  value: 5,
                  message: 'Måste vara minst 5 tecken',
                },
              })}
              error={Boolean(errors.name)}
              helperText={Boolean(errors.name) ? errors.name.message : null}
            />
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h6" component="div">
                  Mängdförteckning
                </Typography>
                <Divider variant="middle" />
              </Grid>
            </Grid>
            <Paper elevation={1} sx={{ margin: '10px 5px', backgroundColor: colors[17].list, padding: '0px 5px' }}>
              <List>
                {activeGroups?.length > 0 && (
                  <>
                    {activeGroups.map((data, index) => (
                      <ListItem
                        key={index}
                        disablePadding
                        sx={index % 2 ? { background: colors[17].oddItems } : { background: colors[17].evenItems }}
                        secondaryAction={
                          <ListItemText
                            primary={
                              <>
                                <IconButton edge="end" aria-label="delete" onClick={() => setOpenCloneGroup({ groupId: data.id, index: index, oldName: data.name })}>
                                  <ContentCopyIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => setOpenDeleteGroup({ id: data.id, index: index })}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            }
                          />
                        }
                      >
                        <ListItemButton>
                          <ListItemText primary={`${data.name}`} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </>
                )}
                <ListItem
                  disablePadding
                  onClick={() => {
                    setOpenAddMultipleItems(true);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<b>{`Lägg till flera typer`}</b>}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
            {geoLocation?.latitude?.toString()}
            {calculations.map((object, index) => populateType(object, index))}
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h6" component="div">
                  Timprislista
                </Typography>
                <Divider variant="middle" />
              </Grid>
            </Grid>
            {calculationsPerHour.map((object, index) => populateType(object, index))}
          </Grid>
          <Grid container item xs={12} justifyContent="space-between" sx={{ padding: '20px' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                remove(ref(database, 'restricted-surveys/' + activeObject.id));
                localStorage.removeItem('activeObject');
                navigate('/home/' + projectId);
              }}
            >
              Avbryt
            </Button>
            <Button variant="contained" type="submit" color="success">
              Skicka
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default NewObject;
