import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';

//const rows = JSON.parse(localStorage.getItem('pricelistData'));

const rows = [
  {
    id: 1,
    key: 'signs1',
    code: 'BCB.7139',
    familyName: 'Tillfällig vägtrafikanordning utöver Grundskyltning',
    name: 'Vägmärke till trafikanordning utöver grundskyltning enligt BCB.7138',
    shortName: null,
    intervalFrom: null,
    intervalTo: null,
    unit: 'st/dygn',
    price: 1,
  },
  {
    id: 2,
    key: 'signs2',
    code: 'BCB.7139',
    familyName: 'Tillfällig vägtrafikanordning utöver Grundskyltning',
    name: 'Sidomarkeringsskärm X3',
    shortName: null,
    intervalFrom: null,
    intervalTo: null,
    unit: 'st/dygn',
    price: 1,
  },
  {
    id: 3,
    key: 'adjustCurbstone',
    code: 'BEB.19',
    familyName: 'Justering av befintlig kantsten av natursten',
    name: 'Höjning max 150 mm, längd > 20 m/objekt',
    shortName: null,
    intervalFrom: null,
    intervalTo: null,
    unit: 'm',
    price: 1,
  },
  {
    id: 4,
    key: 'demolition',
    code: 'BED.12141',
    familyName: 'Rivning av bitumenbundna lager, hela lagertjockleken, tjocklek < 100 mm',
    name: 'Rivning hela lagertjockleken < 50 m²',
    shortName: null,
    intervalFrom: 0,
    intervalTo: 49,
    thicknessFrom: 0,
    thicknessTo: 100,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 40.6,
  },
  {
    id: 5,
    key: 'demolition',
    code: 'BED.12141',
    familyName: 'Rivning av bitumenbundna lager, hela lagertjockleken, tjocklek < 100 mm',
    name: 'Rivning hela lagertjockleken > 50 m²',
    shortName: null,
    intervalFrom: 50,
    intervalTo: null,
    thicknessFrom: 0,
    thicknessTo: 100,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 40.6,
  },
  {
    id: 6,
    key: 'milling',
    code: 'BED.12142',
    familyName: 'Rivning, fräsning av bitumenbundna lager, del av lagertjockleken, tjocklek 0-50 mm',
    name: 'Låd-, Plan- och Anslutningsfräsning < 500 m²',
    shortName: null,
    intervalFrom: 0,
    intervalTo: 499,
    thicknessFrom: 0,
    thicknessTo: 50,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 202.8,
  },
  {
    id: 7,
    key: 'milling',
    code: 'BED.12142',
    familyName: 'Rivning, fräsning av bitumenbundna lager, del av lagertjockleken, tjocklek 0-50 mm',
    name: 'Låd-, Plan- och Anslutningsfräsning 500-1200 m²',
    shortName: null,
    intervalFrom: 500,
    intervalTo: 1200,
    thicknessFrom: 0,
    thicknessTo: 50,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 1,
  },
  {
    id: 8,
    key: 'milling',
    code: 'BED.12142',
    familyName: 'Rivning, fräsning av bitumenbundna lager, del av lagertjockleken, tjocklek 0-50 mm',
    name: 'Låd-, Plan- och Anslutningsfräsning 500-1200 m²',
    shortName: null,
    intervalFrom: 1200,
    intervalTo: null,
    thicknessFrom: 0,
    thicknessTo: 50,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 1,
  },
  {
    id: 9,
    key: 'shafting',
    code: 'CBB.112',
    familyName: 'Jordschakt kategori B för väg, plan o d, Fall B. Avser all typ av schakt inom entreprenaden.',
    name: 'Jordschakt, schaktdjup <100 mm',
    shortName: null,
    intervalFrom: null,
    intervalTo: null,
    thicknessFrom: 0,
    thicknessTo: 99,
    thicknessUnit: 'mm',
    unit: 'm3',
    price: 354.9,
  },
  {
    id: 10,
    key: 'shafting',
    code: 'CBB.112',
    familyName: 'Jordschakt kategori B för väg, plan o d, Fall B. Avser all typ av schakt inom entreprenaden.',
    name: 'Jordschakt, schaktdjup >100 mm',
    shortName: null,
    intervalFrom: null,
    intervalTo: null,
    thicknessFrom: 100,
    thicknessTo: null,
    thicknessUnit: 'mm',
    unit: 'm3',
    price: 20.3,
  },
  {
    id: 11,
    key: 'edgeCutting',
    code: 'CBB.113',
    familyName: 'Jordschakt kategori C för väg, plan o d, Fall B.',
    name: 'Borttagning av vegetation och jord på och utanför vägkant, bredd 10-140 cm.',
    widthFrom: 10,
    widthTo: 140,
    widthUnit: 'cm',
    unit: 'm',
    price: 1,
  },
  {
    id: 12,
    key: 'excavatorAdjustment',
    code: 'DCB.212',
    familyName: 'Förstärkningslager för kategori B till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m.m.',
    name: 'Utläggning av förstärkningslager 200-600 mm med grävmaskin',
    thicknessFrom: 200,
    thicknessTo: 600,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 20.3,
  },
  {
    id: 13,
    key: 'excavatorAdjustment',
    code: 'DCB.212',
    familyName: 'Förstärkningslager för kategori B till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m.m.',
    name: 'Tillfört material krossmaterial av alla fraktioner på objektet',
    unit: 'ton',
    price: 49.7,
    density: 1.85,
  },
  {
    id: 14,
    key: 'excavatorAdjustment',
    code: 'DCB.312',
    familyName: 'Obundet bärlagermaterial kategori B till belagda ytor',
    name: 'Justering bärlager 0-300 mm med grävmaskin',
    thicknessFrom: 0,
    thicknessTo: 300,
    thicknessUnit: 'mm',
    unit: 'm2',
    price: 20.3,
  },
  {
    id: 15,
    key: 'excavatorAdjustment',
    code: 'DCB.312',
    familyName: 'Obundet bärlagermaterial kategori B till belagda ytor',
    name: 'Tillfört krossmaterial av alla fraktioner på objektet',
    unit: 'ton',
    price: 60.8,
    density: 1.85,
  },
  {
    id: 16,
    key: 'supportStrip',
    code: 'DCB.622',
    familyName: 'Stödremsa av obundet slitlagermaterial kategori B och C till belagda ytor',
    name: 'Stödremsa, bredd 250 mm, längd > 200 m/objekt',
    widthFrom: 250,
    widthTo: 250,
    widthUnit: 'mm',
    lengthFrom: 200,
    lengthTo: null,
    lengthUnit: 'm',
    unit: 'ton',
    price: 76,
  },
  {
    id: 17,
    key: 'asphaltLayers',
    code: 'DCC',
    familyName: 'BITUMENBUNDNA ÖVERBYGGNADSLAGER FÖR VÄG, PLAN O D',
    name: 'Byte från grundkrav till sten med kulkvarnsvärde ≤ 7 (>4mm) .',
    unit: 'ton',
    price: 20.9,
  },
  {
    id: 18,
    key: 'asphaltLayers',
    code: 'DCC',
    familyName: 'BITUMENBUNDNA ÖVERBYGGNADSLAGER FÖR VÄG, PLAN O D',
    name: 'Byte från penetrationsbitumen till PMB 40/100-75. Kalkylvärde 6,2%',
    unit: 'ton',
    price: 73.2,
  },
  {
    id: 19,
    key: 'asphaltLayers',
    code: 'DCC',
    familyName: 'BITUMENBUNDNA ÖVERBYGGNADSLAGER FÖR VÄG, PLAN O D',
    name: 'Regleringspris 0,1 % förändrad bindemedelshalt.',
    unit: 'ton',
    price: 2.1,
  },
  {
    id: 20,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 110 kg/m² AG 16/B100-150, bredd > 1,8 m',
    thicknessFrom: 110,
    thicknessTo: 110,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 15.7,
  },
  {
    id: 21,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 110 kg/m² AG 16/B100-150, bredd > 1,8 m',
    intervalFrom: 0,
    intervalTo: 999,
    thicknessFrom: 110,
    thicknessTo: 110,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 15.7,
  },
  {
    id: 22,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 110 kg/m² AG 16/B100-150, bredd > 1,8 m',
    intervalFrom: 1000,
    intervalTo: null,
    thicknessFrom: 110,
    thicknessTo: 110,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 10.5,
  },
  {
    id: 23,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 80 kg/m² ABT 11/B70-100, bredd > 1,8 m',
    intervalFrom: 0,
    intervalTo: 999,
    thicknessFrom: 80,
    thicknessTo: 80,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 98.3,
  },
  {
    id: 24,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 80 kg/m² ABT 11/B70-100, bredd > 1,8 m',
    intervalFrom: 1000,
    intervalTo: null,
    thicknessFrom: 80,
    thicknessTo: 80,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 31.4,
  },
  {
    id: 25,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 80 kg/m² ABS 11/B70-100, bredd > 1,8 m',
    intervalFrom: 0,
    intervalTo: 999,
    thicknessFrom: 80,
    thicknessTo: 80,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 62.7,
  },
  {
    id: 26,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Maskinläggning 80 kg/m² ABS 11/B70-100, bredd > 1,8 m',
    intervalFrom: 1000,
    intervalTo: null,
    thicknessFrom: 80,
    thicknessTo: 80,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 62.7,
  },
  {
    id: 27,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Handläggning 110 kg/m² AG 16/B100-150',
    intervalFrom: 0,
    intervalTo: 29,
    thicknessFrom: 110,
    thicknessTo: 110,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 173.5,
  },
  {
    id: 28,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Handläggning 110 kg/m² AG 16/B100-150',
    intervalFrom: 30,
    intervalTo: null,
    thicknessFrom: 110,
    thicknessTo: 110,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 173.5,
  },
  {
    id: 29,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Handläggning 100 kg/m² ABT 11/B70-100',
    intervalFrom: 0,
    intervalTo: 29,
    thicknessFrom: 100,
    thicknessTo: 100,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 208,
  },
  {
    id: 30,
    key: 'asphaltLayers',
    code: 'DCC.2',
    familyName: 'Bitumenbundna överbyggnadslager kategori B för väg, plan o d',
    name: 'Handläggning 100 kg/m² ABT 11/B70-100',
    intervalFrom: 30,
    intervalTo: null,
    thicknessFrom: 100,
    thicknessTo: 100,
    thicknessUnit: 'kg/m2',
    unit: 'm2',
    price: 208,
  },
  {
    id: 31,
    key: 'newGraniteCurbStone',
    code: 'DEC.11',
    familyName: 'Kantstöd av granit, satta i grus med motstöd av grus',
    name: 'Sättning av kantstöd bredd <15 cm raksten, radiesten exkl. granitsten enligt principritning DEC.11, längd > 20 m/objekt',
    lengthFrom: 20,
    lengthTo: null,
    lengthUnit: 'm',
    unit: 'm',
    price: 212.9,
  },
  {
    id: 32,
    key: 'newConcreteCurbStone',
    code: 'DEC.26',
    familyName: 'Kantstöd av betong, spikade',
    name: 'Kantstöd typ A 10 cm, Längd >10 m. Raksten, radier samt avslut, utförs med motstöd av asfaltmassa 40 kg/m',
    lengthFrom: 10,
    lengthTo: null,
    lengthUnit: 'm',
    unit: 'm',
    price: 212.9,
  },
  {
    id: 33,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Längdsgående markering på väg/gata Bredd 0,1 m, avser alla typer av linjer med olika uppehåll och olika placering',
    unit: 'm',
    price: 1,
  },
  {
    id: 34,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Markering på parkering, skolgård och dylikt samt tvärgående markeringar på alla typer av ytor Bredd 0,1 m',
    unit: 'm',
    price: 1,
  },
  {
    id: 35,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Heldragen linje H(0,2)',
    unit: 'm',
    price: 1,
  },
  {
    id: 36,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Övergångsställe H(0,5)',
    unit: 'm',
    price: 1,
  },
  {
    id: 37,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Väjningslinje',
    unit: 'st',
    price: 1,
  },
  {
    id: 38,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Cykelöverfart 0,5(0,5)+0,5',
    unit: 'st',
    price: 1,
  },
  {
    id: 39,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Stopplinje H(0,4)',
    unit: 'm',
    price: 1,
  },
  {
    id: 40,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Gångsymbol, Cykelsymbol, Handikappsymbol, Bokstäver',
    unit: 'st',
    price: 1,
  },
  {
    id: 41,
    key: 'roadLines',
    code: 'DEE.111',
    familyName: 'Extruderad Thermoplastisk markeringsmassa på trafikyta',
    name: 'Körfältspil',
    unit: 'st',
    price: 1,
  },
  {
    id: 42,
    key: 'recoveryExcavation',
    code: 'DGB.11',
    familyName: 'Återställande av väg, plan o d',
    name: 'Återställning < 2 m2',
    intervalFrom: 0,
    intervalTo: 1.9,
    unit: 'm2',
    price: 129.3,
  },
  {
    id: 43,
    key: 'recoveryExcavation',
    code: 'DGB.11',
    familyName: 'Återställande av väg, plan o d',
    name: 'Återställning 2-10 m2',
    intervalFrom: 2,
    intervalTo: 10,
    unit: 'm2',
    price: 129.3,
  },
  {
    id: 44,
    key: 'recoveryExcavation',
    code: 'DGB.11',
    familyName: 'Återställande av väg, plan o d',
    name: 'Återställning 10,1 - 50 m2',
    intervalFrom: 10.1,
    intervalTo: 49.9,
    unit: 'm2',
    price: 129.3,
  },
  {
    id: 45,
    key: 'recoveryExcavation',
    code: 'DGB.11',
    familyName: 'Återställande av väg, plan o d',
    name: 'Återställning > 50 m2',
    intervalFrom: 50,
    intervalTo: null,
    unit: 'm2',
    price: 129.3,
  },
  {
    id: 46,
    key: 'recoveryGraniteCurbStone',
    code: 'DGB.61',
    familyName: 'Återställande av kantstöd',
    name: 'Granitsten Bredd <15 cm, sten hämtas vid kommunens upplag på Östergatan 8 i Klippan',
    unit: 'm',
    price: 1,
  },
  {
    id: 47,
    key: 'recoveryConcreteCurbStone',
    code: 'DGB.61',
    familyName: 'Återställande av kantstöd',
    name: 'Lim-/Spiksten av betong, inkl. ny betongkantsten',
    unit: 'm',
    price: 1,
  },
  {
    id: 48,
    key: 'changeWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, utbyte av betäckning till teleskopisk/flytande',
    name: 'Nedstigningsbrunn typ A6',
    unit: 'st',
    price: 912.5,
  },
  {
    id: 49,
    key: 'changeWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, utbyte av betäckning till teleskopisk/flytande',
    name: 'Tillsynsbrunn A5, spolbrunn A3, brandpost och rännstensbrunn',
    unit: 'st',
    price: 912.5,
  },
  {
    id: 50,
    key: 'changeWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, utbyte av betäckning till teleskopisk/flytande',
    name: 'Avstängningsventil AV, serviceventil SV, polygonpunkt PP',
    unit: 'st',
    price: 912.5,
  },
  {
    id: 51,
    key: 'adjustSolidWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, höjning av fast betäckning',
    name: 'Nedstigningsbrunn typ A6',
    unit: 'st',
    price: 1,
  },
  {
    id: 52,
    key: 'adjustSolidWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, höjning av fast betäckning',
    name: 'Tillsynsbrunn A5, spolbrunn A3, brandpost och rännstensbrunn',
    unit: 'st',
    price: 1,
  },
  {
    id: 53,
    key: 'adjustSolidWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, höjning av fast betäckning',
    name: 'Avstängningsventil AV, serviceventil SV, polygonpunkt PP',
    unit: 'st',
    price: 1,
  },
  {
    id: 54,
    key: 'adjustFloatingWellCover',
    code: 'PDH.19',
    familyName: 'Arbete med brunnsbetäckningar, höjning av teleskopisk/flytande betäckning',
    name: 'Höjning av teleskopisk- och flytande betäckning i samband med beläggningsarbeten.',
    unit: 'st',
    price: 1,
  },
];
//localStorage.setItem('pricelistData', JSON.stringify(rows));

const columns = [
  { field: 'key', editable: true, headerName: 'Nyckel', width: 150 },
  { field: 'code', editable: true, headerName: 'Kod', width: 150 },
  { field: 'familyName', editable: true, headerName: 'Kodbenämning', width: 400 },
  { field: 'name', editable: true, headerName: 'Benämning', width: 400 },
  { field: 'shortName', editable: true, headerName: 'Kortnamn', width: 150 },
  { field: 'unit', editable: true, headerName: 'Enhet', width: 150 },
  { field: 'price', editable: true, headerName: 'À-pris', width: 150 },
  { field: 'intervalFrom', editable: true, headerName: 'Intervall från', width: 150 },
  { field: 'intervalTo', editable: true, headerName: 'Intervall till', width: 150 },
  { field: 'thicknessFrom', editable: true, headerName: 'Tjocklek från', width: 150 },
  { field: 'thicknessTo', editable: true, headerName: 'Tjocklek till', width: 150 },
  { field: 'thicknessUnit', editable: true, headerName: 'Tjockleksenhet', width: 150 },
  { field: 'widthFrom', editable: true, headerName: 'Bredd från', width: 150 },
  { field: 'widthTo', editable: true, headerName: 'Bredd till', width: 150 },
  { field: 'widthUnit', editable: true, headerName: 'Breddenhet', width: 150 },
  { field: 'lengthFrom', editable: true, headerName: 'Längd från', width: 150 },
  { field: 'lengthTo', editable: true, headerName: 'Längd till', width: 150 },
  { field: 'lengthUnit', editable: true, headerName: 'Längdenhet', width: 150 },
  { field: 'density', editable: true, headerName: 'Densitet', width: 150 },
];

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flex: '1 0 auto',
  ...theme.typography.body2,
}));

const PriceList = () => {
  return (
    <Root>
      <div style={{ width: '100%' }}>
        <DataGrid rows={rows} columns={columns} experimentalFeatures={{ newEditingApi: true }} />
      </div>
    </Root>
  );
};

export default PriceList;
