import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';
import { callAPIMiddleware, callAPIMiddlewareAll } from '../../utils/reducerUtils';

// Initiera Reduxdevtools i webläsaren. måste ha CHROME-extension
const composeEnhancers = process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(callAPIMiddleware, callAPIMiddlewareAll, thunk)));

export default store;
