import { ButtonGroup, Chip, DialogContent, Divider, FormHelperText, Grid, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { Button, StyledChip } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const marksWidth = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

const marksLength = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];
const marksThicknesses = [
  {
    value: 0.01,
    label: '1 cm',
  },
  {
    value: 0.02,
    label: '2 cm',
  },
  {
    value: 0.03,
    label: '3 cm',
  },
  {
    value: 0.04,
    label: '3 cm',
  },
  {
    value: 0.05,
    label: '5 cm',
  },
  {
    value: 0.06,
    label: '6 cm',
  },
  {
    value: 0.07,
    label: '7 cm',
  },
  {
    value: 0.08,
    label: '8 cm',
  },
  {
    value: 0.09,
    label: '9 cm',
  },
  {
    value: 0.1,
    label: '10 cm',
  },
  {
    value: 0.15,
    label: '15 cm',
  },
  {
    value: 0.2,
    label: '20 cm',
  },
  {
    value: 0.25,
    label: '25 cm',
  },
  {
    value: 0.3,
    label: '30 cm',
  },
  {
    value: 0.35,
    label: '35 cm',
  },
  {
    value: 0.4,
    label: '40 cm',
  },
  {
    value: 0.45,
    label: '45 cm',
  },
  {
    value: 0.5,
    label: '50 cm',
  },
  {
    value: 0.55,
    label: '55 cm',
  },
  {
    value: 0.6,
    label: '60 cm',
  },
  {
    value: 0.65,
    label: '65 cm',
  },
  {
    value: 0.7,
    label: '70 cm',
  },
];

const AddKubicDialog = ({ open, onClose, onSubmit, title, fullScreen, fieldName, isEditingField }) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    validateCriteriaMode: 'all',
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const whenSubmit = (data) => {
    const object = {
      name: data.name,
      width: parseInt(data.width).toString(),
      length: parseInt(data.length).toString(),
      thickness: parseFloat(data.thickness).toString(),
      notes: data.notes,
    };
    onSubmit(object, fieldName);
    onClose();
    reset();
  };

  useEffect(() => {
    if (Boolean(isEditingField)) {
      reset(isEditingField.activeObject[isEditingField.index]);
    } else {
      reset({ name: '', width: 6, length: 3, thickness: 0.05, notes: '' });
    }
  }, [isEditingField, reset, fieldName]);

  if (!fieldName) {
    return null;
  }

  return (
    <SimpleDialog open={open} onClose={() => onClose(false)} title={title} fullScreen>
      <form onSubmit={handleSubmit(whenSubmit)}>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label="Benämning"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={Boolean(errors.name) ? errors.name.message : null}
                sx={{ width: '100%', marginBottom: '20px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledChip label="BREDD [m]" />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Controller
                name="width"
                control={control}
                defaultValue={6}
                rules={{ required: ERRORS.required }}
                render={({ field }) => <Slider {...field} valueLabelDisplay="on" step={0.5} marks={marksWidth} min={0.5} max={100} sx={{ width: '90%', marginTop: '30px' }} />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledChip label="LÄNGD [m]" />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Controller
                name="length"
                control={control}
                defaultValue={3}
                rules={{ required: ERRORS.required }}
                render={({ field }) => <Slider {...field} valueLabelDisplay="on" step={0.5} marks={marksLength} min={0.5} max={100} sx={{ width: '90%', marginTop: '30px' }} />}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledChip label="TJOCKLEK [m]" />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Controller
                name="thickness"
                control={control}
                defaultValue={0.05}
                rules={{ required: ERRORS.required }}
                render={({ field }) => <Slider {...field} valueLabelDisplay="on" step={0.05} min={0} max={0.7} sx={{ width: '90%', marginTop: '30px' }} />}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <TextField multiline rows={4} {...register('notes')} label="Anteckningar" variant="outlined" fullWidth sx={{ marginTop: '20px' }}></TextField>
            </Grid>
          </Grid>
          <Button variant="contained" type="submit">
            Spara
          </Button>
        </DialogContent>
      </form>
    </SimpleDialog>
  );
};

export default AddKubicDialog;
