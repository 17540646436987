import { useEffect, useState } from 'react';

const urlParams = new URLSearchParams(window.location.search);
const paramStatic = urlParams.get('static');

export const useGeolocation = (props = {}) => {
  const propStatic = props.static;
  const [geoLocation, setGeoLocation] = useState(null);

  useEffect(() => {
    const isStatic = paramStatic || propStatic;
    if (isStatic) return;

    function success(position) {
      const { coords } = position;
      const { latitude, longitude, accuracy, heading } = coords;
      setGeoLocation({ latitude, longitude, accuracy, heading: Math.round(heading) });
      //   const dx = (-0.5 + Math.random()) / 2000;
      //   const dy = (-0.5 + Math.random()) / 2000;
      //   setGeoLocation({
      //     latitude: latitude + dy,
      //     longitude: longitude + dx,
      //     accuracy,
      //     heading,
      //   });
    }

    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    }

    if ('geolocation' in navigator) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 5000,
      };
      navigator.geolocation.watchPosition(success, error, options);
      //   navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }, [propStatic]);

  useEffect(() => {
    const isStatic = paramStatic || propStatic;
    if (!isStatic) return;
    // Vid mig
    // const latitude = 58.38157049996284;
    // const longitude = 15.678234611109744;
    // const accuracy = 10.123456789;
    // const heading = 47.123456789;
    // Vägtest
    // const latitude = 58.37373895234736;
    // const longitude = 15.66609395710678;
    // const accuracy = 10.123456789;
    // const heading = 30;
    // Klippan
    const latitude = 56.07387982451569;
    const longitude = 13.232941031455992;
    const accuracy = 10.123456789;
    const heading = 30;
    setGeoLocation({ latitude, longitude, accuracy, heading: Math.round(heading) });
  }, [propStatic]);

  return geoLocation;
};
