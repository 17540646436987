import { createReducer, updateObject } from '../../utils/reducerUtils';
import { GBL_RESET, GBL_GET_PROJECTS, GBL_GET_SURVEYS, GBL_GET_RESTRICTED_SURVEYS } from '../actions/globalActions';

const initialState = {
  projects: [],
  surveys: [],
  restrictedSurveys: [],
};
const actionHandlers = {
  // RESET
  [GBL_RESET]: (state, action) => {
    if (action.payload) {
      const resetState = {};
      action.payload.forEach((key) => (resetState[key] = initialState[key]));
      return updateObject(state, resetState);
    } else {
      return { ...initialState };
    }
  },

  // GBL_GET_PROJECTS
  [GBL_GET_PROJECTS]: (state, action) => {
    console.log('REDUCER');
    return updateObject(state, { projects: action.payload });
  },
  // GBL_GET_SURVEYS
  [GBL_GET_SURVEYS]: (state, action) => {
    console.log('REDUCER GBL_GET_SURVEYS');
    return updateObject(state, { surveys: action.payload });
  },

  // GBL_GET_SURVEYS
  [GBL_GET_RESTRICTED_SURVEYS]: (state, action) => {
    console.log('REDUCER GBL_GET_RESTRICTED_SURVEYS');
    return updateObject(state, { restrictedSurveys: action.payload });
  },
};

export default createReducer(initialState, actionHandlers);
