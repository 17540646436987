import { ButtonGroup, Chip, DialogContent, Divider, FormHelperText, Grid, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { Button, StyledChip } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';
import AsphaltLayerContent from './AsphaltLayerContent';

const AsphaltLayer = ({ open, onClose, onSubmit, title, fullScreen, isEditingField }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const whenSubmit = (data) => {
    onSubmit(data, 'asphaltLayers');
    onClose();
  };

  useEffect(() => {
    if (Boolean(isEditingField)) {
      reset(isEditingField.activeObject[isEditingField.index]);
    } else {
      reset({ name: '', asphaltMethod: '', layerType: '', kilograms: '', type: '', stoneSize: '', bitumen: '', width: 6, length: 3, notes: '' });
    }
  }, [isEditingField, reset]);

  return (
    <SimpleDialog open={open} onClose={() => onClose(false)} title="Nytt asfaltslager" fullScreen>
      <form onSubmit={handleSubmit(whenSubmit)}>
        <DialogContent sx={{ padding: '4px' }}>
          <AsphaltLayerContent control={control} errors={errors} register={register} />
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Spara
            </Button>
          </Grid>
        </DialogContent>
      </form>
    </SimpleDialog>
  );
};

export default AsphaltLayer;
