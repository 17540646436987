import { DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import { useMap } from 'react-leaflet/hooks';
import { Marker, Popup } from 'react-leaflet';

import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Map = ({ open, setOpenMap, title, fullScreen, activeObject, calculations }) => {
  console.log('GÅR IN I KARTAN');

  const [mapMarkers, setMapMarkers] = useState([]);

  useEffect(() => {
    const mapMarkersCopy = [];
    console.log('activeObject', calculations);
    calculations.forEach((calc) => {
      const objects = activeObject[calc.fieldName].filter((object) => Boolean(object.location));
      if (objects.length > 0) {
        mapMarkersCopy.push(objects);
      }
    });
    console.log('mapMarkersCopy', mapMarkersCopy);
    setMapMarkers(mapMarkersCopy.flat());
  }, [activeObject, calculations]);

  if (!mapMarkers) {
    return null;
  }

  const bounds = new L.LatLngBounds(mapMarkers.map((marker, index) => [marker.location.latitude, marker.location.longitude]));
  console.log('setMapMarkers', mapMarkers);
  return (
    <SimpleDialog open={Boolean(open)} onClose={() => setOpenMap(false)} title={title} fullScreen>
      <MapContainer bounds={bounds} scrollWheelZoom={false} style={{ height: '100%' }}>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {mapMarkers.length > 0 &&
          mapMarkers.map((marker, index) => {
            console.log('markjer', marker);
            return (
              <Marker key={index} position={[marker.location.latitude, marker.location.longitude]}>
                <Popup>Massa information om denna positonen.</Popup>
              </Marker>
            );
          })}
      </MapContainer>
    </SimpleDialog>
  );
};

export default Map;
