import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDtec0Rw5qLd_ea2fIAhBvzq_H5O-RYgHQ',
  authDomain: 'survia-39e97.firebaseapp.com',
  databaseURL: 'https://survia-39e97-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'survia-39e97',
  storageBucket: 'survia-39e97.appspot.com',
  messagingSenderId: '152437617427',
  appId: '1:152437617427:web:281cbdcf40ff45b6c3e0e6',
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const database = getDatabase(app);

export default database;
