import { ButtonGroup, Chip, DialogContent, Divider, FormHelperText, Grid, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { Button, StyledChip } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const marksInteger = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];
const AddIntegerDialog = ({ open, onClose, onSubmit, title, fullScreen, fieldName, label, isEditingField, schema }) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const whenSubmit = (data) => {
    console.log('data', data, fieldName);
    onSubmit(data, fieldName);
    onClose();
    reset();
  };

  useEffect(() => {
    if (Boolean(isEditingField)) {
      reset(isEditingField.activeObject[isEditingField.index]);
    } else {
      reset({ name: '', [fieldName]: 1, notes: '' });
    }
  }, [isEditingField, reset, fieldName]);

  if (!fieldName) {
    return null;
  }

  // useEffect(() => {
  //   setValue(fieldName, '');
  // }, []);
  console.log('errors', errors);
  return (
    <SimpleDialog open={open} onClose={() => onClose(false)} title={title} fullScreen>
      <form onSubmit={handleSubmit(whenSubmit)}>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label="Benämning"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={Boolean(errors.name) ? errors.name.message : null}
                sx={{ width: '100%', marginBottom: '20px' }}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Controller
                name={fieldName}
                control={control}
                defaultValue={1}
                rules={{ required: ERRORS.required }}
                render={({ field }) => <Slider {...field} valueLabelDisplay="on" step={1} marks={marksInteger} min={1} max={100} sx={{ width: '90%', marginTop: '30px' }} />}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <TextField multiline rows={4} {...register('notes')} label="Anteckningar" variant="outlined" fullWidth sx={{ marginTop: '20px' }}></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit">
                Spara
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </SimpleDialog>
  );
};

export default AddIntegerDialog;
