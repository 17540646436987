import { ButtonGroup, Chip, DialogContent, Divider, FormHelperText, Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { Button } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const integers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
];

const AddObjectDialog = ({ open, onClose, onSubmit, title, fullScreen, fieldName, label, objects, isEditingField }) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const whenSubmit = (data) => {
    console.log('data', data, fieldName);

    const object = objects.find((object) => object.value === data[fieldName]);
    data.unit = object.unit;
    onSubmit(data, fieldName);
    onClose();
    reset();
  };

  useEffect(() => {
    if (Boolean(isEditingField)) {
      reset(isEditingField.activeObject[isEditingField.index]);
    } else {
      reset({ name: '', [fieldName]: 1, length: '', notes: '' });
    }
  }, [isEditingField, reset, fieldName]);

  if (!fieldName || !objects) {
    return null;
  }

  // useEffect(() => {
  //   setValue(fieldName, '');
  // }, []);
  console.log('errors', errors);
  return (
    <SimpleDialog open={open} onClose={() => onClose(false)} title={title} fullScreen>
      <form onSubmit={handleSubmit(whenSubmit)}>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label="Benämning"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={Boolean(errors.name) ? errors.name.message : null}
                sx={{ width: '100%', marginBottom: '20px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Root>
                <Divider>
                  <Chip label={label} />
                </Divider>
              </Root>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={fieldName}
                control={control}
                rules={{ required: ERRORS.required }}
                render={({ field }) => (
                  <ToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors[fieldName]) ? { border: '1px solid red' } : { flexWrap: 'wrap' }}>
                    {objects.map((object, index) => (
                      <ToggleButton key={index} value={object.value} sx={{ width: '100%' }}>
                        {object.value} [{object.unit}]
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              />
              <Grid container item xs={6} justifyContent="center">
                <FormHelperText sx={{ color: 'red' }}>{Boolean(errors[fieldName]) ? errors[fieldName].message : null}</FormHelperText>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'length'}
                control={control}
                rules={{ required: ERRORS.required }}
                render={({ field }) => (
                  <ToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors['length']) ? { border: '1px solid red' } : { flexWrap: 'wrap' }}>
                    {integers.map((int) => (
                      <ToggleButton key={int} value={int} sx={{ width: '40px' }}>
                        {int}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              />
              <Grid container item xs={6} justifyContent="center">
                <FormHelperText sx={{ color: 'red' }}>{Boolean(errors['length']) ? errors['length'].message : null}</FormHelperText>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <TextField multiline rows={4} {...register('notes')} label="Anteckningar" variant="outlined" fullWidth sx={{ marginTop: '20px' }}></TextField>
            </Grid>
          </Grid>
          <Button variant="contained" type="submit">
            Spara
          </Button>
        </DialogContent>
      </form>
    </SimpleDialog>
  );
};

export default AddObjectDialog;
