import axiosDefault from 'axios';

export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

export function insertInArray(array, item) {
  return [...array.slice(0, array.length), item, ...array.slice(array.length)];
}

export function removeItemInArray(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns#updating-an-item-in-an-array
export function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
    };
  });
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export function makeActionCreatorTypes(name) {
  return [`${name}_REQUEST`, `${name}_SUCCESS`, `${name}_FAIL`];
}

export function makeApiActionCreators(name) {
  return {
    Request: `${name}_REQUEST`,
    Success: `${name}_SUCCESS`,
    Fail: `${name}_FAIL`,
  };
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function callAPIMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    console.log('dasd');
    const { types, callAPI, shouldCallAPI = () => true, payload = {}, dispatchBeforeSuccess, dispatchAfterSuccess, wait } = action;
    if (!types || !callAPI) {
      // Normal action: pass it on

      return next(action);
    }

    if (!(typeof types === 'object') || Object.keys(types).length !== 3 || !Object.values(types).every((prop) => typeof prop === 'string')) {
      throw new Error('Expected an object with three properties of type string.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const requestType = types.Request;
    const successType = types.Success;
    const failureType = types.Fail;
    dispatch({ payload, type: requestType });

    return callAPI().then(
      async (response) => {
        if (wait) {
          await sleep(wait);
        }
        if (dispatchBeforeSuccess) {
          dispatch(dispatchBeforeSuccess());
        }
        dispatch({
          payload,
          data: response.data,
          type: successType,
        });
        if (dispatchAfterSuccess) {
          dispatch(dispatchAfterSuccess());
        }
      },
      (error) => {
        return dispatch({ payload, error: error.response, type: failureType });
      }
    );
  };
}

export function callAPIMiddlewareAll({ dispatch, getState }) {
  return (next) => (action) => {
    const { types, calls, shouldCallAPI = () => true, payload = {} } = action;

    if (!types || !calls) {
      // Normal action: pass it on
      return next(action);
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    const requestType = types.Request;
    const successType = types.Success;
    const failureType = types.Fail;

    const datavars = Object.keys(calls);
    const axiosCalls = datavars.map((datavar) => {
      return calls[datavar]();
    });

    // log.debug(datavars);
    // log.debug(axiosCalls);

    dispatch({ ...payload, type: requestType });

    return axiosDefault
      .all(axiosCalls)
      .then(
        axiosDefault.spread((...responses) => {
          const data = {};
          responses.forEach((response, i) => {
            // data[datavars[i]] = response.data.data;
            data[datavars[i]] = response.data;
          });
          return dispatch({
            ...payload,
            data: data,
            type: successType,
          });
        })
      )
      .catch((error) => {
        return dispatch({ ...payload, error: error.response, type: failureType });
      });
  };
}

export const fakeApi = (data, t) => new Promise((r) => setTimeout(() => r({ data }), t));

export const object2QueryParams = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
