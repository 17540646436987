import { Button, Grid, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { onValue, ref, remove, set } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import database from '../../firebase/firebase';
import { getRestrictedSurveys, getSurveys } from '../../redux/actions/globalActions';
import { v4 as uuid } from 'uuid';
import MapIcon from '@mui/icons-material/Map';

function Home(props) {
  const navigate = useNavigate();
  console.log('database', database);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  console.log('projectId home', projectId);

  const surveys = useSelector((state) => state.global.surveys);
  const restrictedSurveys = useSelector((state) => state.global.restrictedSurveys);
  // // Push Function
  // const push = () => {
  //   set(ref(database, 'users/2'), {
  //     username: 'macze',
  //     email: 'macze@macze.aaa',
  //   });
  // };

  const editObject = (object, isRestricted) => {
    if (isRestricted) {
      alert('Någon arbetar aktivt på denna.');
    } else {
      set(ref(database, 'restricted-surveys/' + object.id), true);
      localStorage.setItem('activeObject', JSON.stringify(object));
      navigate('/new-object/' + projectId);
    }
  };
  const newObject = () => {
    localStorage.removeItem('activeObject');
    navigate(`/new-object/${projectId}`);
  };

  useEffect(() => {
    //const objects = localStorage.getItem('allObjects');
    // if (objects) {
    //   const decodedObjects = JSON.parse(objects);
    //   if (decodedObjects) {
    //     setactiveObjects(decodedObjects);
    //   }
    // }

    const projects = ref(database, 'projects/' + projectId + '/surveys');
    onValue(projects, (snapshot) => {
      const projectSurveys = snapshot.val();
      const decodedSurveys =
        projectSurveys &&
        Object.keys(projectSurveys).map((key) => {
          return JSON.parse(projectSurveys[key]);
        });
      console.log('payload', decodedSurveys);

      dispatch(getSurveys(decodedSurveys ? decodedSurveys : []));
    });

    const restrictedSurveys = ref(database, 'restricted-surveys/');
    onValue(restrictedSurveys, (snapshot) => {
      const snapshotRestrictedSurveys = snapshot.val();

      const transformedRestrictedSurveys = snapshotRestrictedSurveys
        ? Object.keys(snapshotRestrictedSurveys).map((key) => {
            return key;
          })
        : [];

      dispatch(getRestrictedSurveys(transformedRestrictedSurveys ? transformedRestrictedSurveys : []));
    });
  }, []);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <h1>Viasur, vi förändrar branschen!</h1>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => newObject()}>
          Nytt objekt
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" component={Link} to={'/pricelist'}>
          Prislista
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => {
            localStorage.removeItem('activeObject');
            localStorage.removeItem('allObjects');
          }}
        >
          Rensa (debug)
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => {
            remove(ref(database, 'restricted-surveys/'));
          }}
        >
          Rensa (låsningar)
        </Button>
      </Grid>
      <Grid item xs={12}>
        <List>
          {surveys.map((object, index) => {
            const isRestricted = restrictedSurveys.includes(object.id);
            return (
              <ListItem
                key={index}
                sx={index % 2 ? { background: '#F5F5F5' } : { background: '#EEEEEE' }}
                onClick={() => editObject(object, isRestricted)}
                // secondaryAction={
                //   <ListItemText
                //     primary={
                //       <IconButton edge="end" aria-label="delete" onClick={() => navigate(`map/${object.id}`)}>
                //         <MapIcon />
                //       </IconButton>
                //     }
                //   />
                // }
              >
                <ListItemText primary={object.name} sx={isRestricted ? { color: 'red' } : {}} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
}

export default Home;
