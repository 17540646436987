export const ERRORS = {
  required: 'Fältet är obligatoriskt',
  emailInvalid: 'Ange en giltig e-postadress',
  phoneInvalid: 'Ange ett giltigt telefonnummer',
  corporateNumberInvalid: 'Ange organisationsnummer enligt format: 123456-1234\n',
  passwordsDontMatch: 'Lösenorden stämmer inte överens',
  passwordTooWeak: 'Lösenordet måste vara mellan 8 och 32 tecken långt och innehålla minst en bokstav, en siffra och ett specialtecken, ex: !@#$%^&()[]+-_.',
};

export const REGEX = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  password: /(?=.*[A-z])(?=.*[0-9])(?=.*\W).{8,32}$/,
  corporateNumber: /^(\d{6})-(\d{4})$/,
};
