import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { Button } from '../MaterialUI';
import CloseIcon from '@mui/icons-material/Close';

export function SimpleDialog(props) {
  const { children, onClose, onSubmit, title, ...rest } = props;

  return (
    <Dialog {...rest}>
      {props.fullScreen && (
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {onSubmit && (
              <Button autoFocus color="inherit" onClick={onSubmit}>
                Spara
              </Button>
            )}
          </Toolbar>
        </AppBar>
      )}
      {children}
    </Dialog>
  );
}
