import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import NewObject from './pages/NewObject/NewObject';
import PriceList from './pages/PriceList/PriceList';
import Projects from './pages/Projects/Projects';
import { createTheme, ThemeProvider } from '@mui/material';
import { blue, grey, orange, red } from '@mui/material/colors';
import 'leaflet/dist/leaflet.css';
function App() {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
    palette: {
      primary: {
        main: blue[700],
      },
      grey: {
        main: grey[700],
        contrastText: '#fff',
      },
      lightgrey: {
        main: grey[500],
        contrastText: '#fff',
      },
      red: {
        main: red[500],
        contrastText: '#fff',
      },
      background: { default: '#253746' },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Projects />} />
          <Route path="home/:projectId" element={<Home />} />
          <Route path="new-object/:projectId" element={<NewObject />} />
          <Route path="pricelist" element={<PriceList />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
