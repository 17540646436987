import { Button, DialogActions, DialogContent, Grid, List, ListItem, ListItemText, TextField } from '@mui/material';
import { get, onValue, push, ref, set } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import database from '../../firebase/firebase';
import { getProjects } from '../../redux/actions/globalActions';
import { v4 as uuid } from 'uuid';

function Projects(props) {
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const projects = useSelector((state) => state.global.projects);

  const onSubmit = (data) => {
    const id = uuid();
    push(ref(database, 'projects/'), {
      name: data.name,
    });
    setOpenCreateProject(false);
  };

  const selectproject = (projectId) => {
    localStorage.setItem('activeProject', JSON.stringify(projectId));
    navigate(`/home/${projectId}/`);
  };

  useEffect(() => {
    // const objects = localStorage.getItem('allObjects');
    // if (objects) {
    //   const decodedObjects = JSON.parse(objects);
    //   if (decodedObjects) {
    //     setactiveObjects(decodedObjects);
    //   }
    // }
    const projects = ref(database, 'projects/');
    onValue(projects, (snapshot) => {
      const payload = snapshot.val();
      dispatch(getProjects(payload));
    });
  }, []);

  return (
    <>
      <SimpleDialog open={openCreateProject} onClose={() => setOpenCreateProject(false)} title="Skapa nytt projekt" fullScreen>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <TextField {...register('name')} label="Projektnamn" variant="outlined" fullWidth></TextField>
          </DialogContent>
          <DialogActions>
            <Grid container item xs={12} justifyContent="space-between">
              <Button onClick={() => setOpenCreateProject(false)}>Avbryt</Button>
              <Button type="submit">Skapa</Button>
            </Grid>
          </DialogActions>
        </form>
      </SimpleDialog>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button variant="contained" onClick={() => setOpenCreateProject(true)} sx={{ margin: '20px' }}>
            Skapa projekt
          </Button>
        </Grid>
        <Grid item xs={12}>
          <List>
            {projects &&
              Object.keys(projects).map((key, index) => (
                <ListItem key={key} sx={index % 2 ? { background: '#F5F5F5' } : { background: '#EEEEEE' }} onClick={() => selectproject(key)}>
                  <ListItemText primary={projects[key].name} />
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

export default Projects;
