import { ButtonGroup, Checkbox, Chip, DialogContent, Divider, FormControlLabel, FormGroup, FormHelperText, Grid, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled, width } from '@mui/system';
import { debounce } from 'debounce';
import React, { useEffect, useState } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { SimpleDialog } from '../../components/UI/Dialogs/Dialog';
import { Button, StyledChip } from '../../components/UI/MaterialUI';
import { ERRORS } from '../../locales/validation';
import AsphaltLayerContent from './AsphaltLayerContent';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const integers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '60',
];

const marksWidth = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
];

const marksLength = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];
const marksThicknesses = [
  {
    value: 0.01,
    label: '1 cm',
  },
  {
    value: 0.05,
    label: '5 cm',
  },
  {
    value: 0.1,
    label: '10 cm',
  },
  {
    value: 0.5,
    label: '50 cm',
  },
  {
    value: 0.7,
    label: '70 cm',
  },
];

const Checkboxes = ({ options, control, name, type }) => {
  const { field } = useController({
    control,
    name,
  });
  const [value, setValue] = React.useState(field.value || []);
  return (
    <>
      {options
        .filter((option) => option.type !== 'object' && !option.multiHidden)
        .map((option, index) => (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  const valueCopy = [...value];

                  // update checkbox value
                  //valueCopy[index] = e.target.checked ? e.target.value : null;
                  const itemIndex = valueCopy.findIndex((object) => object === e.target.value);
                  console.log('itemIndex', itemIndex);
                  if (itemIndex > -1) {
                    valueCopy.splice(itemIndex, 1);
                  } else {
                    valueCopy.push(e.target.value);
                  }

                  // send data to react hook form
                  field.onChange(valueCopy);

                  // update local state
                  setValue(valueCopy);
                }}
                checked={value.includes(option.fieldName)}
                type="checkbox"
                value={option.fieldName}
              />
            }
            label={`${option.label} [${option.unit}]`}
            key={option.fieldName}
          />
        ))}
    </>
  );
};

const AddMultipleItemsDialog = ({ open, onClose, onSubmit, title, fullScreen, fieldName, label, objects, isEditingField, calculations }) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: { selectedTypes: [] },
  });

  const [calculationSchemas, setCalculationSchemas] = useState(null);
  const [integerSchemas, setIntegerSchemas] = useState(null);

  const whenSubmit = (data) => {
    onSubmit(data);
    onClose();
    reset({ selectedTypes: [] });
  };

  useEffect(() => {
    const currentTypes = getValues('selectedTypes');
    const selectedCalculations = calculations.filter((calc) => currentTypes.includes(calc.fieldName));
    const calcSchema = selectedCalculations.map((calc) => {
      return Object.values({ ...calc.schema });
    });
    const selectedIntegerSchemas = selectedCalculations
      .filter((calc) => calc.type === 'integer')
      .map((calc) => {
        return calc;
      });
    const flattenCalcSchema = calcSchema.flat();
    // console.log('calcSchema', calcSchema);
    //const result = Object.values(calcSchema);
    const schema = [...new Set(flattenCalcSchema)];
    setCalculationSchemas(schema);
    setIntegerSchemas(selectedIntegerSchemas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues('selectedTypes'), calculations]);

  if (!open) {
    return null;
  }

  // useEffect(() => {
  //   setValue(fieldName, '');
  // }, []);
  console.log('OPEN', open);
  return (
    <SimpleDialog open={open} onClose={() => onClose(false)} title={title} fullScreen>
      <form onSubmit={handleSubmit(whenSubmit)}>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label="Gruppnamn"
                {...register('groupName', {
                  required: ERRORS.required,
                  minLength: {
                    value: 5,
                    message: 'Måste vara minst 5 tecken',
                  },
                })}
                error={Boolean(errors.name)}
                helperText={Boolean(errors.name) ? errors.name.message : null}
                sx={{ width: '100%', marginBottom: '20px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Checkboxes options={calculations} control={control} name="selectedTypes" />
              </FormGroup>
              {watch('selectedTypes').length > 0 && watch('selectedTypes').find((type) => type === 'asphaltLayers') && (
                <AsphaltLayerContent control={control} errors={errors} register={register} exclude={['width', 'length', 'notes']} />
              )}
              {/* {watch('selectedTypes').length > 0 &&} */}
              {calculationSchemas.includes('width') && (
                <>
                  <Grid item xs={12}>
                    <StyledChip label="BREDD" />
                  </Grid>

                  <Grid container item xs={12} justifyContent="center">
                    <Controller
                      name="width"
                      control={control}
                      defaultValue={3}
                      rules={{ required: ERRORS.required }}
                      render={({ field }) => <Slider {...field} valueLabelDisplay="on" marks={marksWidth} step={0.5} min={0.5} max={30} sx={{ width: '90%', marginTop: '30px' }} />}
                    />
                  </Grid>
                </>
              )}
              {calculationSchemas.includes('length') && (
                <>
                  <Grid item xs={12}>
                    <StyledChip label="LÄNGD" />
                  </Grid>

                  <Grid container item xs={12} justifyContent="center">
                    <Controller
                      name="length"
                      control={control}
                      defaultValue={3}
                      rules={{ required: ERRORS.required }}
                      render={({ field }) => <Slider {...field} valueLabelDisplay="on" marks={marksLength} step={0.5} min={0.5} max={100} sx={{ width: '90%', marginTop: '30px' }} />}
                    />
                  </Grid>
                </>
              )}
              {calculationSchemas.includes('thickness') && (
                <>
                  <Grid item xs={12}>
                    <StyledChip label="TJOCKLEK" />
                  </Grid>

                  <Grid container item xs={12} justifyContent="center">
                    <Controller
                      name="thickness"
                      control={control}
                      defaultValue={0.05}
                      rules={{ required: ERRORS.required }}
                      render={({ field }) => <Slider {...field} valueLabelDisplay="on" marks={marksThicknesses} step={0.05} min={0} max={0.7} sx={{ width: '90%', marginTop: '30px' }} />}
                    />
                  </Grid>
                </>
              )}
              {integerSchemas &&
                integerSchemas.map((schema) => {
                  console.log('HEJ', schema);
                  return (
                    <>
                      <Grid item xs={12}>
                        <StyledChip label={schema.label} />
                      </Grid>

                      <Grid container item xs={12} justifyContent="center">
                        <Controller
                          name={schema.fieldName}
                          control={control}
                          defaultValue={1}
                          rules={{ required: ERRORS.required }}
                          render={({ field }) => <Slider {...field} valueLabelDisplay="on" marks={marksLength} step={0.5} min={0.5} max={100} sx={{ width: '90%', marginTop: '30px' }} />}
                        />
                      </Grid>
                    </>
                  );
                })}
              {/* <Controller
                name={fieldName}
                control={control}
                rules={{ required: ERRORS.required }}
                render={({ field }) => (
                  <ToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors[fieldName]) ? { border: '1px solid red' } : { flexWrap: 'wrap' }}>
                    {objects.map((object, index) => (
                      <ToggleButton key={index} value={object.value} sx={{ width: '100%' }}>
                        {object.value} [{object.unit}]
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              />
              <Grid container item xs={6} justifyContent="center">
                <FormHelperText sx={{ color: 'red' }}>{Boolean(errors[fieldName]) ? errors[fieldName].message : null}</FormHelperText>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'length'}
                control={control}
                rules={{ required: ERRORS.required }}
                render={({ field }) => (
                  <ToggleButtonGroup exclusive {...field} color="primary" sx={Boolean(errors['length']) ? { border: '1px solid red' } : { flexWrap: 'wrap' }}>
                    {integers.map((int) => (
                      <ToggleButton key={int} value={int} sx={{ width: '40px' }}>
                        {int}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              />
              <Grid container item xs={6} justifyContent="center">
                <FormHelperText sx={{ color: 'red' }}>{Boolean(errors['length']) ? errors['length'].message : null}</FormHelperText>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <TextField multiline rows={4} {...register('notes')} label="Anteckningar" variant="outlined" fullWidth sx={{ marginTop: '20px' }}></TextField> */}
            </Grid>
          </Grid>
          <Button variant="contained" type="submit">
            Spara
          </Button>
        </DialogContent>
      </form>
    </SimpleDialog>
  );
};

export default AddMultipleItemsDialog;
