import React from 'react';
import { TextField as MUITextField, Button as MUIButton, Chip } from '@mui/material';
import { styled } from '@mui/system';

export const TextField = styled(MUITextField)(({ theme, color }) => ({
  margin: '1em 0',
  width: '100%',
}));

export const Button = styled(MUIButton)(({ theme, color }) => ({
  padding: '8px 12px',
  margin: '1em 0',
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '3px',
  margin: '5px 0px',
}));
