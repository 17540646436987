// import axios from 'axios-backend';
// import { makeApiActionCreators } from 'utils/reducerUtils';

import { unstable_useId } from '@mui/material';
import { onValue, ref, set } from 'firebase/database';
import database from '../../firebase/firebase';
import { v4 as uuid } from 'uuid';

export const GBL_RESET = 'GBL_RESET';
export const GBL_GET_PROJECTS = 'GBL_GET_PROJECTS';
export const GBL_GET_SURVEYS = 'GBL_GET_SURVEYS';
export const GBL_GET_RESTRICTED_SURVEYS = 'GBL_GET_RESTRICTED_SURVEYS';
export const GBL_CREATE_PROJECTS = 'GBL_CREATE_PROJECTS';

export const globalReset = (payload) => ({ type: GBL_RESET, payload });

export const getProjects = (payload) => {
  console.log('data', payload);
  return {
    type: GBL_GET_PROJECTS,
    payload: payload,
  };
};

export const getSurveys = (payload) => {
  console.log('data', payload);
  return {
    type: GBL_GET_SURVEYS,
    payload: payload,
  };
};
export const getRestrictedSurveys = (payload) => {
  return {
    type: GBL_GET_RESTRICTED_SURVEYS,
    payload: payload,
  };
};
